<template>
  <!--  <div class="relative">-->
  <!--    <p>-->
  <span
    class="p"
    v-for="(letter, index) in spanWords"
    :key="index"
    :class="{
      s: letter === ' ',
    }"
  >
    <span
      ref="words"
      class="l"
      :class="{
        s: letter === ' ',
        'opacity-0': props.hiddenBefore,
      }"
    >
      {{ letter }}
    </span>
  </span>
  <!--    </p>-->
  <!--  </div>-->
</template>

<script setup>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useWaitForPageTransitionEnd } from "~~/composables/waitPageTransition";

const { promise } = useWaitForPageTransitionEnd();

const props = defineProps({
  text: {
    default: "",
    type: String,
  },
  wordsMode: {
    default: false,
    type: Boolean,
  },
  hiddenBefore: {
    default: true,
    type: Boolean,
  },
  delay: {
    default: "0.25",
    type: String,
  },
  stagger: {
    default: "0.05",
    type: String,
  },
});

// const spanText = computed(() => props.text.replace(/\S/g, "<span>$&</span>"));
const spanWords = computed(() => {
  return props.text.match(/\S+|\s/g);
});
const words = ref([]);

let tween = null;

onMounted(async () => {
  await promise.value;

  tween = gsap.fromTo(
    words.value,
    {
      y: "100%",
      opacity: 0,
    },
    {
      scrollTrigger: {
        trigger: words.value,
        start: "top 100%",
        // end: "bottom 80%",
        toggleActions: "play none none none",
      },
      duration: 0.5,
      y: "0%",
      opacity: 1,
      ease: "power1.easeOut",
      delay: props.delay,
      // stagger: 0.03,
      stagger: (function () {
        let i = -1;
        let lastOffset = null;
        return function (index, target, list) {
          if (lastOffset === null || target.offsetTop > lastOffset) {
            lastOffset = target.offsetTop;
            i++;
          }
          return parseFloat(props.stagger) * i;
        };
      })(),
    },
  );
});

onBeforeUnmount(() => {
  if (tween) {
    tween.kill();
    tween = null;
  }
});
</script>

<style scoped>
div {
  display: inline-block;
}

span.l:not(.s) {
  display: inline-block;
}

span.p:not(.s) {
  display: inline-block;
  overflow: hidden;
}
span {
  vertical-align: top;
}
</style>
