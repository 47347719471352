<!--<template><div v-html="spanText"></div></template>-->
<template>
  <span
    class="p"
    v-for="(letter, index) in spanLetters"
    :key="index"
    :class="{
      s: letter === ' ',
    }"
  >
    <span
      ref="letters"
      class="l"
      :class="{
        s: letter === ' ',
        'opacity-0': props.hiddenBefore,
      }"
      >{{ letter }}</span
    >
  </span>
</template>

<script setup>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useWaitForPageTransitionEnd } from "~~/composables/waitPageTransition";
const { promise } = useWaitForPageTransitionEnd();

const props = defineProps({
  text: {
    default: "",
    type: String,
  },
  wordsMode: {
    default: false,
    type: Boolean,
  },
  hiddenBefore: {
    default: true,
    type: Boolean,
  },
  delay: {
    default: "0",
    type: String,
  },
  stagger: {
    default: "0.003",
    type: String,
  },
});

// const spanText = computed(() => props.text.replace(/\S/g, "<span>$&</span>"));
const spanLetters = computed(() => {
  if (props.wordsMode) {
    return props.text.match(/\S+|\s/g);
  } else {
    return props.text.split("");
  }
});
const letters = ref([]);

let tween;

onMounted(async () => {
  // await promise.value;
  await promise.value;

  tween = gsap.fromTo(
    letters.value,
    {
      y: "100%",
      opacity: 0.3,
    },
    {
      scrollTrigger: {
        trigger: letters.value,
        start: "top 100%",
        // end: "bottom 80%",
        toggleActions: "play none none none",
      },
      duration: 0.5,
      y: "0%",
      opacity: 1,
      ease: "power1.easeOut",
      delay: props.delay,
      stagger: props.stagger,
    },
  );
});

onBeforeUnmount(() => {
  if (tween) {
    tween.kill();
    tween = null;
  }
});
</script>

<style scoped>
div {
  display: inline-block;
}

span {
  vertical-align: text-top;
}

span.l:not(.s) {
  display: inline-block;
}
span.p:not(.s) {
  display: inline-block;
  overflow: hidden;
}
</style>
